:root {
    --color-primary: #3366FF;
    --color-secondary: #264070;
    --color-bg: #EDF3FF;
    --font-primary: 'Rubik', system-ui, -apple-system, 'Roboto', sans-serif;
}

body {
    font-family: var(--font-primary);
}

p {
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    opacity: .7;
}

h2 {
    position: relative;
    margin-bottom: 40px;
}

h2.center {
    text-align: center;
}

h2:before {
    content: "";
    position: absolute;
    height: 7px;
    width: 70px;
    background-image: url(../images/lines.svg);
    bottom: -20px;
}

h2.center:before {
    height: 7px;
    width: 70px;
    background-image: url(../images/lines.svg);
    bottom: -20px;
    left: 47%;
}

h3 {
    font-size: 26px;
}

.navbar ul li a {
    color: #000000;
    opacity: .8;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.navbar ul li.active a {
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--color-primary);
}

.btn-auth,
.btn-action {
    border-radius: 55px;
    padding: 10px 20px;
    color: #FFFFFF !important;
    opacity: 1 !important;
    background-color: var(--color-secondary);
    font-size: 16px !important;
}

.btn-primary-2 {
    border-radius: 55px !important;
    padding: 10px 20px !important;
    color: #FFFFFF !important;
    opacity: 1 !important;
    background-color: var(--color-secondary) !important;
    font-size: 16px !important;
}

.btn-auth span,
.btn-action {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.mobile-logo {
    margin-top: 82px;
}

.main,
.breadcrumbs {
    background-color: var(--color-primary);
}

.main .child,
.breadcrumbs .inner {
    background-image: url(../images/bg-1.png);
}

.main .child .container {
    text-align: center;
    padding: 96px 0 200px 0;
    min-height: 100%;
}

.main .child h1,
.main .child p {
    color: #ffffff;
}

.main .child p {
    margin-top: 25px !important;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
}

.breadcrumbs h1,
.breadcrumbs p {
    color: #ffffff;
}

.btn-primary {
    background-color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;
}

.btn-convert {
    border-radius: 7px !important;
    background-color: var(--color-secondary) !important;
    border-color: var(--color-secondary) !important;
}

.btn-convert .MuiLoadingButton-loadingIndicator {
    color: #ffffff;
}

#footer {
    background-color: var(--color-secondary);
    padding-bottom: 1px;
}

#footer .colm-1 {
    text-align: center;
}

#footer .colm-1 p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 25px;
}

#footer h5 {
    color: #ffffff;
    position: relative;
    margin-bottom: 25px;
}

#footer a,
#footer p,
#footer .contact svg {
    color: #ffffff;
    opacity: .7;
}

#footer a:hover {
    opacity: 1;
}

#footer hr {
    color: #ffffff;
    opacity: .5;
}

#footer .contact svg {
    float: left;
    font-size: 22px;
}

#footer .contact p {
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
}

#footer .contact a {
    font-size: 16px;
    line-height: 24px;
    padding-left: 10px;
}

#footer h5:before,
#profile h2::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 40px;
    background-color: var(--color-primary);
    top: 34px;
}

.copyright {
    text-align: center;
}

.copyright a {
    opacity: 1 !important;
}

.copyright p {
    font-size: 13px;
}

.section-bg {
    background-color: var(--color-bg);
}

.mt-unset {
    margin-top: unset !important;
}

#faq .section-bg,
.privacy .section-bg {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.how-it-works .steps svg {
    font-size: 36px;
}

.how-it-works .steps p {
    margin-top: 20px;
}

.how-it-works .steps .icon, .why-choose .icon {
    position: relative;
    padding: 6px;
    border-radius: 50%;
    color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: #3366ff81;
}

.how-it-works .steps .icon-main, .why-choose .icon-main {
    position: relative;
    padding: 15px;
    border-radius: 50%;
    color: #ffffff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-primary);
}

.card {
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 60px #586EB529;
    border-radius: 9px;
    border: none;
}

.ocr-widget {
    margin-top: -180px;
}

.drop-area {
    width: 100%;
    border: 2px dashed var(--color-primary);
    background-color: #F0F5FF;
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.drop-area svg {
    color: var(--color-primary);
}

.language {
    margin-bottom: 30px;
}

.pricing {
    text-align: center;
    margin-top: 58px;
}

.pricing .features p {
    font-size: 14px;
    margin-bottom: 4px;
}

.pricing .btn-action {
    margin-top: 20px;
}

.pricing .type-1 .amount {
    font-size: 4em;
}

.pricing .currency {
    font-size: 38px;
}

.pricing .amount {
    font-size: 4em;
    color: var(--color-primary);
}

.pricing .type-1 {
    margin: 10px 30px 10px 30px;
    padding: 50px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 80px #00000029;
    border-radius: 29px;
}

.pricing .type-2 {
    margin: 10px 30px 10px 30px;
    padding: 50px;
    background-color: #182A50;
    box-shadow: 0px 3px 80px #00000029;
    border-radius: 29px;
}

.pricing .type-2 .btn-action {
    background-color: var(--color-primary);
}

.pricing .type-2 h3 {
    color: #ffffff;
    opacity: 1;
}

.pricing .type-2 .currency,
.pricing .type-2 p.validity,
.pricing .type-2 p {
    color: #ffffff;
    opacity: .9;
}

.pricing p.validity {
    color: var(--color-primary);
}

.language-box h3 {
    font-size: inherit;
    font-weight: inherit;
    padding-top: 3px;
    padding-bottom: 3px;
}

.language-box a:hover h3 {
    color: var(--color-primary);
}

#about {
    margin-top: 124px;
    margin-bottom: 124px;
    padding: 50px;
    /* padding-left: unset; */
    border-radius: 36px;
    background-color: #3F77FC;
}

#about a {
    color: #ffffff;
}

#about h2,
#about p {
    color: #FFFFFF;
    opacity: .9;
}

#about h2 {
    opacity: 1;
}

#about h2:before {
    all: unset;
}

.img-preview {
    border: 1px solid #dadce0;
    border-radius: 3px;
}

#contact .card {
    padding: 35px;
}

#contact h2 {
    font-size: 26px;
}

#contact .info svg {
    float: left;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    font-size: 40px;
    color: var(--color-primary);
}

#contact .info p {
    margin-top: 25px;
    padding-left: 55px;
}

#contact .info a {
    color: #000;
}

#contact .info a:hover {
    color: var(--color-primary);
}

.form-control {
    margin-top: 1.5rem !important;
    padding: 0.75rem 0.75rem;
}

.form-control:focus {
    border-color: unset;
    box-shadow: unset;
}

.react-tel-input .form-control {
    border: none !important;
    width: 100%;
}

.phone-input-field {
    margin-top: 1.5rem !important;
    padding: 0.50rem 0.50rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background: none !important;
}


.privacy h2 {
    margin-top: 35px;
    opacity: .7;
}

#auth .card {
    padding: 35px;
}

#profile .MuiTabs-indicator {
    transform: scale(1.05, 0.05);
    width: 30px;
    left: 0;
    height: 38px !important;
    margin-top: 5px;
}

#profile .MuiButtonBase-root {
    align-items: flex-start;
    transition: .5s;
    font-size: 16px;
    text-transform: none;
}

#profile .MuiButtonBase-root.MuiChip-root {
    align-items: unset;
    transition: unset;
    font-size: 12px;
    text-transform: unset;
    height: 22px;
}

#profile .MuiButtonBase-root.Mui-selected {
    padding-left: 50px;
}

#profile .card {
    padding: 25px;
}

#profile .card.content {
    padding: 5px;
    padding-top: unset;
}

#profile h2 {
    font-size: 22px;
    margin-bottom: 40px;
}

#profile h3 {
    font-size: 18px !important;
    color: #1c1e21;
}

#profile table {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

#profile .MuiTableContainer-root {
    box-shadow: unset;
}

#profile table th {
    font-weight: 500;
}

#profile table tr {
    border-bottom: 1px solid #dadce0;
}

#profile table td {
    width: 70%;
}

#profile table.payment td {
    width: unset;
}

#profile table th,
.order-summary table th {
    padding-top: 8px;
    padding-bottom: 8px;
}

#profile .form-control {
    margin-top: 10px !important;
}

.verify {
    font-size: 12px;
}

.verify span {
    cursor: pointer;
    color: var(--color-primary);
}

.pdf {
    color: #DB0701;
}

.google-login {
    background-color: rgb(24 118 210) !important;
    color: rgb(255 255 255) !important;
}

.order-summary .card {
    margin-top: 70px;
    padding: 30px;
}

.order-summary h3 {
    color: #1c1e21;
    font-size: 20px;
    opacity: .8;
}

section#subscribe {
    padding-top: unset;
}

h2.model::before {
    all: unset;
}

#profile .card,
.sign-in .card {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-fix {
    margin-top: 15px !important;
}

a.forgot {
    text-transform: uppercase;
    text-align: right;
    color: #1976D2;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

#forgot-pw .card {
    padding: 30px;
    padding-bottom: unset;
}

#faq h2 {
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 10px;
    opacity: .7;
}

.privacy h2 {
    margin-top: 40px;
    margin-bottom: 10px;
    opacity: .7;
}

#faq p,
.privacy p {
    opacity: .7;
}

#faq h2::before,
.privacy h2::before {
    all: unset;
}

#faq hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

p.email {
    font-size: 14px;
    color: #000;
    opacity: .8;
}

.list {
    margin-top: 2rem;
}

.list svg {
    float: left;
}

.list p {
    padding-left: 35px;
}

.lang-list {
    margin-top: 52px;
    margin-bottom: 52px;
}

.app-screen {
    margin-top: -210px;
    margin-bottom: -300px;
}

.features h3 {
    opacity: .7;
    font-weight: 400;
    font-size: 20px;
    padding-top: 4px;
    padding-left: 45px;
}

.features svg {
    font-size: 18px;
}

.features .feature {
    margin-top: 15px;
    margin-bottom: 15px;
}

.features .icon {
    float: left;
    padding: 2px 5px 4px 5px;
    border-radius: 50%;
    color: #ffffff;
    width: fit-content;
    background-color: var(--color-primary);
}

.docs-wrapper .sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 30px;
}

.docs-wrapper .main {
    padding: 30px;
}

.docs-wrapper .main ol li {
    padding-top: 7px;
    padding-bottom: 7px;
    opacity: .8;
}

.docs-wrapper .sidebar a {
    color: #000;
    opacity: .8;
}

.docs-wrapper .main p {
    font-size: 16px;
}

.docs-wrapper .main h2 {
    font-size: 24px;
    font-weight: 400;
    margin-top: 70px;
    margin-bottom: unset;
}

.docs-wrapper .main h2::before {
    all: unset;
}

.docs-wrapper .main :first-child h2 {
    margin-top: unset;
}

.docs-wrapper .main h3 {
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 10px;
}

.docs-wrapper .main h4 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}

.docs-wrapper .main h5 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
}

.docs-wrapper .main table {
    width: 100%;
}

.docs-wrapper .main table td,
.docs-wrapper .main table th {
    padding: 10px;
    vertical-align: top;
}

.docs-wrapper .main table thead {
    line-height: 30px;
    background-color: rgb(230, 223, 223);
    border-bottom: 1px solid rgba(0, 0, 0, 0.507);
}

pre.code {
    border-radius: 10px;
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.836);
    color: #FFFFFF;
}

.lang-codes .col-md-3 {
    margin-top: 5px;
    margin-bottom: 5px;
}

#steps .steps-paragraph {
    margin-left: 66px;
}

#steps p {
    margin-bottom: unset;
}

#banner {
    padding: unset;
}

#banner .main .child .container {
    text-align: center;
    padding: 50px 0 50px 0;
    min-height: 100%;
}

#banner .main .child .container p a {
    color: #fff;
    text-decoration: underline;
}

#banner .main .child .container p {
    opacity: 0.9 !important;
}

#banner .main .child .container p a:hover {
    color: #91c8ef;
}

#ocr-cards h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 15px 0;
}

#ocr-cards img {
    width: 30%;
}

#contact ul li {
    font-size: 18px;
    color: #000000;
    opacity: .7;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.buttons-pos {
    display: flex;
}

.videowrapper {
    position: relative;
    padding-bottom: 60.25%;
    height: 0;
}

.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 400px;
}

@media (max-width: 768px) {
    .videowrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 350px;
        height: 200px;
    }
}

.border-thin {
    border-width: 1px !important;
}

.mui-table p {
    margin: unset;
}

.alert-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1400;
}

.alert-enter {
    opacity: 0;
    transform: translateX(100%);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
    transform: translateX(0);
}

.alert-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

.table-striped>tbody>tr:nth-child(2n+1)>td,
.table-striped>tbody>tr:nth-child(2n+1)>th {
    background-color: #EDF3FF;
}

.dropzone-disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #e2e2e2;
}

@media (min-width: 1200px) {
    .custom-xl-5 {
        width: 20%;
        max-width: 20%;
    }
}
