@media (max-width: 768px) {
    .main .child .container {
        padding: 45px 0 190px 0;
    }

    .main .child p {
        width: 86%;
    }

    .steps .col-sm-12 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    section.how-it-works {
        padding-top: unset;
    }

    .how-it-works .steps .icon {
        float: left;
    }

    .how-it-works .steps p {
        margin-top: 13px;
        text-align: left;
        padding-left: 100px;
    }

    #footer .colm-1 {
        text-align: left;
    }

    #footer .colm-1 p {
        width: unset;
        margin-left: unset;
        margin-right: unset;
    }

    #footer h5 {
        margin-top: 15px;
    }

    #about {
        padding: 38px;
        margin-bottom: unset;
    }

    .btn-auth {
        width: 100%;
        text-align: center;
    }

    #faq .Mui-expanded.MuiPaper-root {
        border-radius: 42px 41px 20px 20px !important;
    }

    .app-screen {
        margin-bottom: unset;
    }

    #ocr-cards h4{
        font-size: 18px;
    }
    
    #ocr-cards img{
        width: 20%;
    }

    #contact .rest-api-image{
        margin-bottom: 20px;
    }
    .buttons-pos{
        display: flex;
        flex-direction: column;
    }
    .ml-4{
        margin-left: unset !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .pricing .type-1, .pricing .type-2 {
        padding: 30px;
        margin: unset;
    }

    #about {
        padding: 28px;
        margin-top: 18px;
        margin-bottom: 30px;
    }

    .app-screen {
        margin-top: -108px;
    }
}

@media (max-width: 991px) {
    .app-screen {
        margin-bottom: unset;
    }

}

@media (max-width: 1200px) {
    .navbar ul li {
        padding: 10px;
    }

    .pricing .type-1, .pricing .type-2 {
        padding: 28px !important;
    }

    #about {
        margin-bottom: unset;
    }
}

@media (min-width: 1150px){
    #contact .rest-api-image{
        width: 85%;
    }
}