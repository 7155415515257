/* Header */

#header {
    position: relative;
    z-index: 99;
}

#header .container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* Navbar */

#navbar ul {
    justify-content: flex-end;
}

.navbar ul {
    margin-top: 20px;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 30px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

a {
    text-decoration: none;
}

.MuiTypography-root, h2, h3 {
    color: #000000;
    opacity: .8;
}

section {
    padding-top: 50px;
    padding-bottom: 50px;
}

#footer ul {
    list-style: none;
    padding-left: 0;
}

#footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}